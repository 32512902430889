<template>
  <div class="login_box">
    <div class="l_container" v-if="!domain">
      <img class="left_logo" src="../../common/imgs/login/logo_innobuilder.png" alt="">
      <div class="l_form_box">
        <div class="l_form_right">
          <div class="form_header flex-x-between flex-y-c">
            <img src="../../../public/static/images/logo/login_logo.png" alt="">

            <div>
            <span @click="changeLanguage('zh_CN')" :class="{
              main_theme_color_999: lang === 'en_US',
              main_theme_color_333: lang === 'zh_CN'
            }" class="fz16 curp">CN</span>
              <span @click="changeLanguage('en_US')" :class="{
              main_theme_color_999: lang === 'zh_CN',
              main_theme_color_333: lang === 'en_US'
            }" class="fz16 ml32 curp">EN</span>
            </div>
          </div>
          <h5 class="l_form_right_logo fzBold">{{ $t('login.login.xgmm') }}</h5>
<!--
          <p class="fz14 main_theme_color_333 mt6">{{ $t('login.login.hyldylpt') }}</p>
-->
          <el-form :rules="rules" class="mt32" ref="form" :model="params"
                   :label-width="`${lang === 'en_US' ? 1.6 : 0.95}rem`">
            <el-form-item class="mb34" :label="$t('login.retrievePaw.sjh') + ' : '" prop="mobile">
              <el-input type="number" :placeholder="$t('login.retrievePaw.srsjh')" v-model="params.mobile" />
            </el-form-item>
            <el-form-item class="mb34" :label="$t('login.retrievePaw.yzm') + ' : '" prop="smsCaptcha">
              <el-input type="number" :placeholder="$t('login.retrievePaw.sryzm')" v-model="params.smsCaptcha">
                <template slot="append">
                <span :class="isYzm ? 'yzm_active' : ''" class="get-message" @click="getYzm">
                  {{ isYzm ? `${$t('login.retrievePaw.cxhq')}${yzmTime}s` : $t('login.retrievePaw.hqyzm') }}
                </span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="mb34" :label="$t('login.retrievePaw.xmm') + ' : '" prop="loginPwd">
              <el-input maxlength="20" type="password" :placeholder="$t('login.retrievePaw.xmminit')"
                        v-model="params.loginPwd" autocomplete="new-password" />
            </el-form-item>
            <el-form-item :label="$t('login.retrievePaw.qrmm') + ' : '" prop="loginPwd01">
              <el-input maxlength="20" type="password" :placeholder="$t('login.retrievePaw.qqrmm')"
                        v-model="params.loginPwd01" autocomplete="new-password" />
            </el-form-item>
          </el-form>
          <div class="btn_box main_theme_color_333" :class="{ btn_box_en: lang === 'en_US' }">
            <div class="btn_box_back flex-x-y-c curp" @click="goBack">{{ $t('login.retrievePaw.fh') }}</div>
            <div :class="{ btn_ok_en: lang === 'en_US' }" class="btn_ok flex-x-y-c curp" @click="subRetrievePaw">
              {{ $t('login.retrievePaw.qd') }}</div>
          </div>
        </div>
<!--        <p class="bottom_text" :class="{ bottom_text_en: lang === 'en_US' }">{{ $t('login.login.jszc') }}-->
<!--          <a href="https://www.aigrin.cn" target="_blank">-->
<!--            <span class="curp main_theme_color_333">{{ $t('login.login.gfwz') }}</span>-->
<!--          </a>-->
<!--        </p>-->
      </div>
    </div>
    <div class="duole-container" v-else>
      <div class="duole-content">
        <div class="login-wrap">
          <div class="login-header">
            <div class="logo"></div>
            <div class="locale-switch">
              <span :class="{
                main_theme_color_999: lang === 'en_US',
                main_theme_color_333: lang === 'zh_CN',
              }" @click="changeLanguage('zh_CN')">ZH</span>
              /
              <span :class="{
                main_theme_color_999: lang === 'zh_CN',
                main_theme_color_333: lang === 'en_US',
              }" @click="changeLanguage('en_US')">EN</span>
            </div>
          </div>
          <div class="welcome-label">
            <div>{{ $t('login.login.xgmm') }}</div>
            <div>{{ $t('login.login.yxjzyspt') + $t('login.login.yxjzyspt') }}</div>
          </div>
          <div class="form-content">
            <el-form :rules="rules" class="mt32" ref="form" :model="params"
              :label-width="`${lang === 'en_US' ? 1.6 : 0.95}rem`">
              <el-form-item class="mb24" :label="$t('login.retrievePaw.sjh') + ' : '" prop="mobile">
                <el-input class="form-input" type="number" :placeholder="$t('login.retrievePaw.srsjh')"
                  v-model="params.mobile" />
              </el-form-item>
              <el-form-item class="mb24" :label="$t('login.retrievePaw.yzm') + ' : '" prop="smsCaptcha">
                <el-input class="form-input" type="number" :placeholder="$t('login.retrievePaw.sryzm')"
                  v-model="params.smsCaptcha">
                  <template slot="append">
                    <span :class="isYzm ? 'yzm_active' : ''" class="main_theme_color" @click="getYzm">
                      {{ isYzm ? `${$t('login.retrievePaw.cxhq')}${yzmTime}s` : $t('login.retrievePaw.hqyzm') }}
                    </span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item class="mb24" :label="$t('login.retrievePaw.xmm') + ' : '" prop="loginPwd">
                <el-input class="form-input" maxlength="20" type="password" :placeholder="$t('login.retrievePaw.xmminit')"
                  v-model="params.loginPwd" autocomplete="new-password" />
              </el-form-item>
              <el-form-item :label="$t('login.retrievePaw.qrmm') + ' : '" prop="loginPwd01">
                <el-input class="form-input" maxlength="20" type="password" :placeholder="$t('login.retrievePaw.qqrmm')"
                  v-model="params.loginPwd01" autocomplete="new-password" />
              </el-form-item>
            </el-form>
            <!-- <div :class="{ dis_btn: !isDisBtn }" class="submit" @click="toLogin">{{ $t("login.login.dl") }}</div> -->
          </div>
          <div class="btn-options">
            <div class="btn_box_back flex-x-y-c curp" @click="goBack">{{ $t('login.retrievePaw.fh') }}</div>
            <div :class="{ btn_ok_en: lang === 'en_US' }" class="btn_ok flex-x-y-c curp" @click="subRetrievePaw">
              {{ $t('login.retrievePaw.qd') }}</div>
          </div>
          <div class="technical-support">{{ $t('login.login.dljszc') }}</div>
        </div>
      </div>
    </div>

    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import { notifyMsg } from 'common/js/util';
import { forgetLoginPwd, sendCaptcha_new } from 'common/api/user';
import FullLoading from 'components/full-loading/full-loading';
import LangStorage from 'common/js/cookie';

export default {
  data() {
    return {
      domain: window.location.host.includes('duole'),
      isLoading: false,
      isYzm: false,
      yzmTime: 59,
      params: {
        mobile: '',
        smsCaptcha: '',
        loginPwd: '',
        loginPwd01: '',
        userKind: 'DOCTOR',
        merchantType: '2'
      },
      interTime: null,
      lang: localStorage.getItem('user_lang') || 'zh_CN',
      rules: {
        mobile: [
          { required: true, message: this.$t('login.retrievePaw.qxtxsjh'), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (/^1[3|4|5|6|7|8|9]\d{9}$/.test(value) == false) {
                callback(new Error(this.$t('login.retrievePaw.qzqtx')));
              } else {
                callback();
              }
            }, trigger: 'blur'
          },
        ],
        smsCaptcha: [
          { required: true, message: this.$t('login.retrievePaw.sryzm'), trigger: 'blur' },
        ],
        loginPwd: [
          { required: true, message: this.$t('login.retrievePaw.xmminit'), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (/^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9]{6,20}/.test(value) == false) {
                callback(new Error(this.$t('login.retrievePaw.xmminit')));
              } else {
                callback();
              }
            }, trigger: 'blur'
          },
        ],
        loginPwd01: [
          { required: true, message: this.$t('login.retrievePaw.qqrmm'), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.params.loginPwd) {
                callback(new Error(this.$t('login.retrievePaw.mmbyz')));
              } else {
                callback();
              }
            }, trigger: 'blur'
          },
        ]
      }
    }
  },
  created() {
    document.body.style.height = '100%';
  },
  methods: {
    changeLanguage(type) {
      if (type === this.lang) return;
      this.$i18n.locale = type;
      LangStorage.setLang(this.$i18n.locale);
      window.location.reload();
    },
    getYzm() {
      const { mobile } = this.params;
      if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(mobile)) {
        return ''
      }
      if (this.isYzm) {
        return false;
      }
      if (this.interTime) {
        clearInterval(this.interTime);
      }
      this.isLoading = true;
      sendCaptcha_new({
        bizType: 'FORGET_LOGINPWD',
        mobile,
        userKind: 'DOCTOR',
        merchantType: '2'
      }).then(() => {
        this.isYzm = true;
        this.isLoading = false;
        this.interTime = setInterval(() => {
          if (this.yzmTime < 1) {
            this.isYzm = false;
            this.yzmTime = 59;
            clearInterval(this.interTime);
          }
          this.yzmTime--;
        }, 1000);
      }).catch(() => {
        this.isLoading = false;
        this.isYzm = false;
        this.yzmTime = 59;
      });
    },
    goBack() {
      this.$router.push('/login');
    },
    subRetrievePaw() {
      this.$refs['form'].validate((val) => {
        if (val) {
          const { loginPwd, loginPwd01 } = this.params;
          if (loginPwd.length < 6) {
            return notifyMsg(this, 'warning', this.$t('login.retrievePaw.xmminit'));
          } else {
            this.isLoading = true;
            forgetLoginPwd(this.params).then(() => {
              this.isLoading = false;
              notifyMsg(this, 'success', this.$t('login.retrievePaw.czcg')).then(() => {
                this.$router.push('/login');
              });
            }).catch(() => {
              this.isLoading = false;
            });
          }
        }
      });
    }
  },
  components: {
    FullLoading
  },
  beforeDestroy() {
    if (this.interTime) {
      clearInterval(this.interTime);
    }
  }
}
</script>

<style scoped lang="scss">
.duole-container {
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(90deg, #FED400 0%, rgba(253, 213, 11, 0.96) 9%, rgba(252, 218, 43, 0.84) 26%, rgba(251, 225, 95, 0.65) 48%, rgba(249, 235, 166, 0.39) 83%, rgba(247, 247, 247, 0.1) 100%);

  .duole-content {
    position: relative;
    width: 100%;
    height: 100%;
    background: url('../../common/imgs/login/duole-bg.webp') no-repeat left center;
    background-size: auto 100%;
  }

  .welcome-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #333333;
    padding-left: 185px;

    .big-title {
      width: fit-content;
      font-size: 144px;
      line-height: 209px;
      border-bottom: 2px solid #333333;
    }

    .en-message {
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 20px;
      margin: 32px 0 8px;
    }

    .zh-message {
      max-width: 650px;
      font-size: 40px;
      line-height: 58px;
      font-weight: 600;
    }

  }

  .login-wrap {
    position: absolute;
    right: 193px;
    top: 50%;
    transform: translateY(-50%);
    width: 646px;
    height: 736px;
    border-radius: 16px;
    padding: 64px;
    background-color: #ffffff;

    .technical-support {
      white-space: nowrap;
      position: absolute;
      font-size: 14px;
      line-height: 20px;
      position: absolute;
      bottom: -24px;
      left: 50%;
      transform: translate(-50%, 100%);
    }

    .login-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: 112px;
        height: 48px;
        background: url('../../common/imgs/login/duole-logo.png') no-repeat center;
        background-size: 100% 100%;
      }

      .locale-switch {
        font-size: 16px;
        line-height: 22px;
        color: #333333;

        span {
          cursor: pointer;
        }

      }

    }

    .welcome-label {
      margin: 64px 0 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      font-size: 32px;
      line-height: 38px;
      color: #333333;

      div {
        font-size: 14px;
        line-height: 22px;

        &:first-child {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 4px;

          &::before {
            content: '';
            width: 4px;
            height: 22px;
            background-color: $main_theme_color;
            margin-right: 10px;
          }

        }

      }
    }

    .form-content {

      .form-input {
        width: 100%;
        height: 48px;

        /deep/ .el-input-group__append {
          border: none !important;
        }

        /deep/ .el-input__inner {
          height: 48px;
          border-radius: 12px;
          border: none;
          background-color: #f2f2f2 !important;
        }

        &:first-child {
          margin-bottom: 24px;
        }

      }

      .forget-password {
        cursor: pointer;
        margin-top: 16px;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
      }

      .submit {
        cursor: pointer;
        margin-top: 112px;
        width: 100%;
        height: 48px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $main_theme_color;
        color: #333333;
        font-size: 18px;
        color: #333333;
      }

    }

    .btn-options {
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        border-radius: 12px;
        font-size: 18px;
        color: #333333;

        &:first-child {
          width: 120px;
          margin-right: 20px;
          border: 1px solid #bbbbbb;
        }

        &:last-child {
          flex: 1;
          background-color: $main_theme_color;
        }

      }

    }

  }

}

//.bottom_text {
//  font-size: 0.14rem;
//  color: $main_theme_color_333;
//  position: absolute;
//  bottom: 110px;
//  right: 363px;
//}

//.bottom_text_en {
//  right: 280px !important;
//}

.login_box {
  height: 100vh;
  position: relative;

  .left_logo {
    display: block;
    width: 284px;
    height: 112px;
    position: absolute;
    left: 406px;
    top: 50%;
    transform: translateY(-50%);
  }

  .l_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-image: url("../../../public/static/images/logo/logo_backdrop.png");
    position: relative;

    .l_form_box{
      width: 6.46rem;
      height: 6.25rem;
      position: absolute;
      top: 1.31rem;
      right: 2.6rem;
      .l_form_right {
        background-color: white;
        width: 6.46rem;
        height: 6.25rem;
        padding: 0.6rem 1rem 0 1rem;
        border-radius: 16px;


        .l_form_right_logo {
          margin-top: 0.5rem;
          font-size: 0.24rem;
          color: #333333;
          border-left: 4px $main_theme_color solid;
          padding-left: 10px;
        }

        .l_f_box {
          margin-bottom: 0.32rem;

          .l_f_single {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 0.25rem;

            .s_label {
              display: inline-block;
              min-width: 1rem;
              margin-right: 0.11rem;
              color: $main_theme_color_999;
            }

            .s_input {
              width: 100%;
              display: flex;
              align-items: center;
              overflow: hidden;
              font-size: 0.19rem;
              position: relative;
              border-radius: 0.05rem;

              input {
                height: 0.48rem;
                outline: none;
                background-color: transparent;
                flex: 1;
                padding-left: 0.15rem;
                font-size: 0.16rem;
              }


            }
          }
        }

        .btn_box {
          display: flex;
          align-items: center;
          padding-left: 95px;

          .btn_box_back {
            width: 1.2rem;
            height: 0.48rem;
            border-radius: 6px;
            border: 1px solid #BBBBBB;
            font-size: 18px;
          }

          .btn_ok {
            width: 2.16rem;
            height: 0.48rem;
            background-color: $main_theme_color;
            border-radius: 6px;
            font-size: 18px;
            margin-left: 24px;
          }

          .btn_ok_en {
            width: 1.5rem !important;
          }
        }

        .btn_box_en {
          padding-left: 160px;
        }

        .f_r_foo {
          display: flex;
          justify-content: space-between;
          font-size: 0.16rem;

          .f_r_foo_wj {
            color: #999;
            cursor: pointer;
          }

          .f_r_foo_xz {
            color: #666;
            font-family: PingFang-SC-Bold;
            display: flex;
            align-items: center;
            cursor: pointer;

            .xz_icon {
              display: inline-block;
              width: 0.18rem;
              height: 0.16rem;
              margin-right: 0.1rem;
              background-size: 100% 100%;
              background-image: url("/static/images/web_download_app.png");
            }
          }
        }
      }
      .bottom_text {
        font-size: 0.14rem;
        color: $main_theme_color_333;
        position: absolute;
        bottom: -0.4rem;
        text-align: center;
        width: 100%;
        //right: 363px;
      }
    }
  }

  .l_foo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.22rem 0 0.26rem;

    .l_foo_t {
      display: flex;
      align-items: center;
      margin-bottom: 0.22rem;

      p {
        padding: 0 0.58rem;
        color: #333333;
        font-size: 0.16rem;
        cursor: pointer;
      }

      .foo_p_b {
        border-right: 0.01rem solid #B1B1B1;
        border-left: 0.01rem solid #B1B1B1;
      }
    }

    .l_foo_p {
      color: #999;
      font-size: 0.14rem;
    }
  }

}

.yzm_active {
  color: $main_theme_color_999;
  cursor: pointer;
}

/deep/ .el-form-item__label {
  margin-top: 5px;
}

/deep/ .el-input__inner {
  border: 0.01rem solid #BBBBBB;
  height: 0.52rem;
}

/deep/ .el-input-group__append,
.el-input-group__prepend {
  background-color: #fff !important;
  border: 0.01rem solid #BBBBBB !important;
  border-left: none !important;
  cursor: pointer;
}
</style>
